import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import BackButton from '../components/common/BackButton.jsx';
import Button from '../components/common/Button.jsx';
import LangIcon from '../components/common/LanguageIcon.jsx';
import TableOfContents from '../components/composite/TableOfContents.jsx';

import projectsData from '../data/projectData';


function Pixel() {
  const location = useLocation();

  useEffect(() => {
    if (location.hash === '') {
      window.scrollTo(0, 0);
    }
  }, [location]);

  const project = projectsData.find(p => p.title === "Mock Search");

  if (!project) {
    return <div>Project not found</div>;
  }

  const sections = [
    { id: 'backstory', title: 'Backstory' },
    { id: 'features', title: 'Features' },
    { id: 'build', title: 'Build' },
    { id: 'system-design', title: 'System Design' },
    { id: 'challenges', title: 'Challenges' },
    { id: 'future-steps', title: 'Future Steps' },
  ];

  return (
    <div className="w-[95%] md:w-10/12 mx-auto">
        <BackButton link="/"/>
        <section id="heading" className="flex flex-col-reverse justify-between items-center gap-8 md:mb-40 md:items-start md:flex-row">
            <div>
                <h1 className="heading mb-4">{project.title}</h1>
                <p className="subtitle-regular mb-12">{project.description}</p>
                <p className="grey-text mb-4">{project.date}</p>
                <p className="grey-text mb-8"><span className="bold-grey-text">Role: </span>{project.role}</p>
                <div className="flex gap-4 mb-12 flex-col md:flex-row">
                    <Button styleType="filled" text="GitHub" link={project.github} />
                    <Button styleType="unfilled" type="demo" text="Play Demo" link={project.demo} />
                </div>
                <div className="flex flex-wrap gap-[20px] my-[5px]">
                    {project.languages.map((language, index) => (
                        <LangIcon key={index} language={language} size="medium" />
                    ))}
                </div>
            </div>
            <img className="w-4/6 mb-4 md:mb-12 md:w-[37%]" src={`${process.env.PUBLIC_URL}${project.mockup}`} alt="Pixel Mockup"/>
        </section>

        <div className="flex flex-row gap-12 justify-between">
          <div className="hidden md:block">
            <TableOfContents sections={sections}/>
          </div>
          <div className="flex flex-col gap-48 w-full mt-24 md:mt-0 md:w-9/12">

            <section id="backstory" className="flex flex-col">
              <h1 className="heading mb-16">Backstory</h1>
              <p className="grey-text">After completing a Python course at university, which was my first college-level class 
                focused on the language, I became deeply interested in machine learning. Although I had some prior experience with 
                Python from high school, this course significantly expanded my understanding and proficiency.<br/><br/>
                Motivated by my newfound interest, I embarked on a very basic project to create a simple program that mimics some 
                aspects of machine learning. The goal was to predict a user's demographic based on mock Google searches. The data 
                points for this project were synthetically generated by AI, and I used hard-coded keywords to make these 
                predictions.<br/><br/>
                While this project was extremely rudimentary and only touched on the basic theory of what machine learning models 
                do, it served as an introductory exercise for me. By manually implementing these basic concepts, I gained a 
                clearer understanding of how machine learning models operate at a fundamental level. This project not only 
                reinforced my Python skills but also laid the foundation for my continued exploration and study of more advanced 
                machine learning techniques and applications.</p>
            </section>

            <section id="features" className="flex flex-col">
              <h1 className="heading mb-16">Features</h1>
              <div className="flex flex-col gap-16">
                {project.features.map((feature, index) => (
                <div key={index} className="flex flex-col gap-16 mb-8 md:mb-0 md:flex-row">
                  <img className="w-full object-contain md:w-[45%]" src={`${process.env.PUBLIC_URL}${feature.image}`} alt={feature.title} />
                  <div className="flex flex-col gap-4">
                    <h3 className="subtitle-bold">{feature.title}</h3>
                    <p className="grey-text">{feature.description}</p>
                  </div>
                </div>
                ))}
              </div>
            </section>

            <section id="build" className="flex flex-col">
              <h1 className="heading mb-16">Build</h1>
                <p className="grey-text">The development of this Python program involved several technical components, focusing on 
                    user interaction, data handling, and demographic prediction logic. The 
                    program <span className="bold-gradient-text">runs in the terminal</span> and allows users to input three mock 
                    Google searches, which are then used to predict demographic characteristics based on synthetic data.<br/><br/>
                    At the core of the program is the <span className="bold-gradient-text">&apos;User&apos; class</span>, which 
                    encapsulates all data and actions related to the user. This class includes attributes such as gender, age, 
                    marital status, and salary/class. Structuring the program around this class ensures that user data is organized 
                    and easily accessible throughout the execution of the program.<br/><br/>
                    To enable demographic predictions, <span className="bold-gradient-text">synthetic data</span> was created with 
                    predefined keywords for each category within each demographic. These keywords are associated with likelihood 
                    percentages, based on societal stereotypes. For instance, the keyword "Disney" might have a higher likelihood 
                    percentage for older, married individuals compared to young children. This data forms the basis of the 
                    prediction mechanism.<br/><br/>
                    When the user inputs their three mock searches, the program parses each search to identify any matching 
                    keywords from the synthetic data. This involves checking each search term against a hardcoded bank of keywords. 
                    The keywords found are then aggregated to determine their respective likelihood percentages for each 
                    demographic category.<br/><br/>
                    The program then calculates the most likely demographic characteristics by selecting 
                    the <span className="bold-gradient-text">highest percentage for each category</span>. These characteristics are 
                    assigned to a `User` object, which is then returned to the user with the predicted demographics 
                    outlined.<br/><br/>
                    Additionally, the program offers an option for users to see an example of how it works before entering their 
                    searches. This feature helps users understand the process and optimize their search entries for more accurate 
                    results.<br/><br/>
                    Overall, the project showcases a fundamental approach to machine learning by simulating basic prediction 
                    logic, enhancing understanding of how these models operate under the hood.</p>
            </section>

            <section id="system-design">
              <h1 className="heading mb-16">System Design</h1>
              <img src={`${process.env.PUBLIC_URL}${project.systemDesign}`} alt="System Design"/>
            </section>

            <section id="challenges" className="flex flex-col">
              <h1 className="heading mb-16">Challenges</h1>
                <p className="grey-text">The most difficult aspect of this project was making plausible predictions with hardcoded 
                    data. Unlike real machine learning algorithms that predict unknown criteria using large datasets, this project 
                    relied on predefined probabilities associated with chosen keywords. This limitation meant that if a user's 
                    search did not contain any of the hardcoded keywords, no prediction could be made. Additionally, ensuring the 
                    hardcoded probabilities were realistic and reflective of actual demographic trends was challenging. However, 
                    this project provided valuable experience in understanding the fundamentals of machine learning and highlighted 
                    the complexities involved in creating accurate predictive models.</p>
            </section>

            <section id="future-steps" className="flex flex-col">
              <h1 className="heading mb-16">Future Steps</h1>
                <p className="grey-text">Looking ahead, I plan to build out a front-end web application for this program, providing 
                    users with an intuitive interface to input their three mock Google searches. This enhancement will improve user 
                    experience and make the program more accessible. Additionally, I aim to create and train a real machine 
                    learning model using scikit-learn. This model will be capable of making predictions based on searches that do 
                    not fall within the current set of hardcoded keywords, significantly increasing the accuracy and versatility of 
                    the predictions. These future steps will not only refine the project's functionality but also deepen my 
                    understanding and application of advanced machine learning techniques.</p>
            </section>

            </div>
        </div>
    </div>
  );
}

export default Pixel;
