import React from 'react';

function Footer() {
  return (
    <div>
        <p className="footer text-center mt-80 mb-10">Made and Developed by Nick Marker &copy; 2024 </p>
    </div>
  );
}

export default Footer;
